<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <b-row>
          <b-col
            cols="12"
            md="12"
          >
            <b-input-group class="mb-1">
              <b-button
                v-if="$ability.can('store', 'admin:categories')"
                variant="primary"
                :to="{ name: 'admin:post-categories-store' }"
              >
                <span class="text-nowrap">{{ $t('general.add') }}</span>
              </b-button>
            </b-input-group>

            <b-table
              ref="refListTable"
              class="position-relative"
              :items="categories"
              responsive
              :fields="tableColumns"
              primary-key="id"
              show-empty
              :empty-text="$t('market.table.settings.empty_text')"
            >

              <template #cell(status)="data">
                {{ $t(`general.statuses.${data.item.status}`) }}
              </template>

              <template #cell(actions)="data">
                <router-link
                  v-if="$ability.can('update', 'admin:categories')"
                  :to="{ name: 'admin:post-categories-update', params: { id: data.item.id } }"
                  class="btn btn-primary btn-sm mr-1"
                >
                  <feather-icon
                    icon="EditIcon"
                    size="16"
                    class="align-middle"
                  />
                </router-link>
                <span
                  v-if="$ability.can('destroy', 'admin:categories')"
                  class="btn btn-danger btn-sm"
                  @click="destroy(data.item.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle"
                  />
                </span>
              </template>

            </b-table>

          </b-col>
        </b-row>

      </div>
    </b-card>
  </div>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  mixins: [GlobalMixin],
  data() {
    return {
      tableColumns: [
        { key: 'title', label: this.$t('market.table.fields.title'), sortable: false },
        { key: 'status', label: this.$t('market.table.fields.status'), sortable: false },
        { key: 'actions', label: this.$t('market.table.fields.actions') },
      ],
      searchword: '',
      categories: [],
    }
  },
  beforeCreate() {
    this.$http.get('/api/admin/categories', {
      params: {
        type: 'post',
      },
    })
      .then(response => {
        this.categories = response.data
      })
  },
  methods: {
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/categories/${id}`)
          .then(() => this.fetchData())
          .catch(error => {
            this.fetchData()
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      }, {
        title: this.$t('notifications.confirm.destroy.category'),
        text: null,
      })
    },
    fetchData() {
      this.$http.get('/api/admin/categories', {
        params: {
          type: 'post',
        },
      })
        .then(response => {
          this.categories = response.data
        })
    },
  },
}
</script>
